// import axios from "axios";

// const token = localStorage.getItem('token');
// const orgId = localStorage.getItem('orgId');
// const candidateId = localStorage.getItem('candidateId');

// export default axios.create({
//   baseURL: `https://rushivarya-stg-loksevak-dot-bhmd-dev-app-navya.el.r.appspot.com/api/v1/user/${orgId}/${candidateId}`,
//   headers: {
//     "Content-type": "application/json",
//     "Authorization": token,
//   }
// });

import axios from 'axios';

const apiClient = axios.create({
  baseURL: `https://rushivarya-prd-loksevak-mix-dot-bhmd-dev-app-navya.el.r.appspot.com/api/v1/user`,
  headers: {
    'Content-type': 'application/json'
  }
});

apiClient.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  const orgId = localStorage.getItem('orgId');
  const candidateId = localStorage.getItem('candidateId');

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  config.url = `${config.baseURL}/${orgId}/${candidateId}${config.url}`;

  return config;
}, error => {
  return Promise.reject(error);
});

export default apiClient;
