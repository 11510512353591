<template>
  <div class="card mb-4">
    <div
      class="card-header pb-0 d-flex justify-content-between align-items-center"
    >
      <h6>{{ $t("request_vehicles") }}</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("purpose_of_vehicle") }}
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("capacity") }}
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("event") }}
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("location") }}
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("select_vehicle") }}
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ $t("action") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(vehicle, index) in paginatedReqVehicles" :key="index">
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{
                    vehicle.vehicle_type == 1 ? $t("carrier") : $t("passenger")
                  }}
                </p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ vehicle.capacity }}
                </p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ vehicle.events ? vehicle.events.event_name : "" }}
                </p>
              </td>
              <td>
                <i
                  class="fas fa-map-marker-alt text-danger"
                  @click="openMapPopup(vehicle.device_location.coordinates)"
                ></i>
              </td>
              <td>
                <select
                  v-model="vehicle.selectedVehicle"
                  class="form-control"
                  id="vehicle"
                  required
                >
                  <option value="" selected>{{ $t("select_vehicle") }}</option>
                  <option
                    v-for="availableVehicle in availableVehicles"
                    :key="availableVehicle.id"
                    :value="availableVehicle.id"
                  >
                    {{ availableVehicle.vehicle_name }}
                  </option>
                </select>
              </td>
              <td class="align-middle">
                <select
                  v-model="vehicle.status"
                  class="form-select me-3"
                  @change="handleStatusChange(vehicle, index)"
                >
                  <option disabled value="">{{ $t("select_action") }}</option>
                  <option value="Approve">{{ $t("approve") }}</option>
                  <option value="Cancel">{{ $t("reject") }}</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination mt-4">
          <button @click="prevPage" :disabled="currentPage === 1">
            {{ $t("previous") }}
          </button>
          <span>{{ currentPage }} / {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage === totalPages">
            {{ $t("next") }}
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="showMapPopup"
      class="modal fade show d-block"
      tabindex="-1"
      role="dialog"
      style="background: rgba(0, 0, 0, 0.5)"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <button
            type="button"
            class="close"
            @click="showMapPopup = false"
            aria-label="Close"
            style="
              background-color: #141727;
              color: white;
              border: 2px solid #141727;
              border-radius: 50%;
              width: 30px;
              margin-left: 95%;
            "
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-body">
            <GoogleMap :coordinates="selectedMapLocation" />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="showCancelModal"
      class="modal fade show d-block"
      tabindex="-1"
      role="dialog"
      style="background: rgba(0, 0, 0, 0.5)"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <h6 style="padding-left: 20px; padding-top: 20px">
            {{ $t("reject_vehicle_request") }}
          </h6>
          <div class="modal-header">
            <button
              type="button"
              class="close"
              @click="showCancelModal = false"
              aria-label="Close"
              style="
                background-color: #141727;
                color: white;
                border: 2px solid #141727;
                border-radius: 50%;
                width: 30px;
                margin-left: 92%;
                margin-top: -50px;
              "
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="mt-3">
              <label for="cancelReason"
                >{{ $t("reason_for_rejection") }}:</label
              >
              <input
                v-model="cancelReason"
                type="text"
                class="form-control"
                id="cancelReason"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="showCancelModal = false"
            >
              {{ $t("close") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="cancelVehicle"
              style="
                background-color: #141727;
                color: white;
                border: 2px solid #141727;
              "
            >
              {{ $t("submit") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoogleMap from "./GoogleMap2.vue";
import "@fortawesome/fontawesome-free/css/all.css";
import RequestVehiclesDataService from "../../services/RequestVehiclesDataService.js";
import VehicleDataService from "../../services/VehicleDataService.js";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "RequestVehicle",
  components: {
    GoogleMap,
  },
  data() {
    return {
      reqvehicles: [],
      availableVehicles: [],
      cancelReason: "",
      currentIndex: null,
      showCancelModal: false,
      showMapPopup: false,
      selectedMapLocation: null,
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  created() {
    this.getAllReqVehicle();
    this.getAllVehicles();
  },
  computed: {
    paginatedReqVehicles() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.reqvehicles.slice(start, start + this.itemsPerPage);
    },
    totalPages() {
      return Math.ceil(this.reqvehicles.length / this.itemsPerPage);
    },
  },
  methods: {
    getAllReqVehicle() {
      RequestVehiclesDataService.getAll()
        .then((response) => {
          this.reqvehicles = response.data.request.map((vehicle) => ({
            ...vehicle,
            selectedVehicle: "", // Add a selectedVehicle field for each vehicle
            status: "", // Add a status field for each vehicle
          }));
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAllVehicles() {
      VehicleDataService.getAll()
        .then((response) => {
          this.availableVehicles = response.data.vehicles;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    handleStatusChange(vehicle, index) {
      if (vehicle.status === "Cancel") {
        this.openCancelModal(index);
      } else if (vehicle.status === "Approve") {
        this.approveVehicle(vehicle);
      }
    },
    approveVehicle(vehicle) {
      const tenantId = localStorage.getItem("tenantId");
      if (!vehicle.selectedVehicle) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please select a vehicle!",
          confirmButtonText: "OK",
        });
      } else {
        const params = {
          id: vehicle.id,
          vehicle_id: vehicle.selectedVehicle,
        };

        const token = localStorage.getItem("token");
        axios
          .get(
            `https://rushivarya-prd-loksevak-mix-dot-bhmd-dev-app-navya.el.r.appspot.com/api/v1/admin/${tenantId}/request-vehicle/accept`,
            {
              params,
              headers: {
                Authorization: token,
              },
            }
          )
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Vehicle approved successfully!",
              confirmButtonText: "OK",
            }).then(() => {
              this.getAllReqVehicle();
            });
            vehicle.status = "Approved";
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.msg
            ) {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: error.response.data.msg,
                confirmButtonText: "OK",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "There was an error approving the vehicle!",
                confirmButtonText: "OK",
              });
            }
            console.error("There was an error approving the vehicle!", error);
          });
      }
    },
    openCancelModal(index) {
      this.currentIndex = index;
      this.showCancelModal = true;
    },
    cancelVehicle() {
      const tenantId = localStorage.getItem("tenantId");
      if (this.cancelReason.trim() === "") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please provide a reason for rejection!",
          confirmButtonText: "OK",
        });
        return;
      }

      const vehicle = this.reqvehicles[this.currentIndex];
      const params = {
        id: vehicle.id,
        rejection_reason: this.cancelReason,
      };

      const token = localStorage.getItem("token");
      axios
        .get(
          `https://rushivarya-prd-loksevak-mix-dot-bhmd-dev-app-navya.el.r.appspot.com/api/v1/admin/${tenantId}/request-vehicle/deny`,
          {
            params,
            headers: {
              Authorization: token,
            },
          }
        )
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Vehicle request rejected successfully!",
            confirmButtonText: "OK",
          }).then(() => {
            this.getAllReqVehicle();
          });
          this.showCancelModal = false;
        })
        .catch((error) => {
          console.error(
            "There was an error rejecting the vehicle request!",
            error
          );
        });
    },
    openMapPopup(coordinates) {
      this.selectedMapLocation = coordinates;
      this.showMapPopup = true;
    },
  },
};
</script>

<style scoped>
.modal-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
}
.modal-body {
  padding: 10px;
}
.modal-footer {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination button {
  padding: 5px 10px;
  margin: 0 5px;
  border: none;
  background-color: #141727;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}
.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.pagination span {
  padding: 0 10px;
}
</style>
