<template>
  <div class="py-4 container-fluid">
    <!-- Existing content -->
    <div class="row">
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          :title="$t('no_of_voters')"
          :value="openSurveys"
          :percentage="{
            value: '+20%',
            color: 'text-success',
          }"
          :icon="{
            component: 'ni ni-money-coins',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          :title="$t('Voters_info')"
          :value="surveyQuestions"
          :percentage="{
            value: '+10%',
            color: 'text-success',
          }"
          :icon="{
            component: 'ni ni-book-bookmark',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0">
        <mini-statistics-card
          :title="userType === '1' ? $t('No of Booth') : $t('No of Department')"
          :value="vehicles"
          :percentage="{
            value: '+5%',
            color: 'text-success',
          }"
          :icon="{
            component: 'ni ni-ambulance',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>

      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          :title="$t('title_total_users')"
          :value="totalUsers"
          :percentage="{
            value: '+3%',
            color: 'text-success',
          }"
          :icon="{
            component: 'ni ni-chat-round',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <GoogleMapForDashboard :markers="mapMarkers" />
      </div>
    </div>
    <div class="row" style="margin-top: 20px">
      <div
        class="col-lg-12"
        style="background-color: white; border-radius: 10px"
      >
        <h6
          class="text-center text-secondary font-weight-bolder mb-3"
          style="margin-top: 10px"
        >
          {{ $t("Top 5 Voting Centers") }}
        </h6>
        <!-- Table Heading -->
        <div class="table-responsive" style="margin-top: -15px">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  AC No
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Part No
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Voting Center
                </th>

                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Voter Count
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Voters Reached
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                  style="color: red"
                >
                  %
                </th>
                <!-- New column -->
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(center, index) in sortedVotingCentersDescending"
                :key="index"
              >
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ center.ac_no }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ center.partno }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ center.vcenter }}
                  </p>
                </td>

                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ center.voter_count }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ center.voters_reached }}
                  </p>
                </td>
                <td>
                  <p
                    class="text-xs font-weight-bold mb-0"
                    :class="{
                      'percentage-red': center.voter_count === 0,
                      'percentage-green': center.voter_count > 0,
                    }"
                  >
                    {{
                      center.voter_count > 0
                        ? (
                            (center.voters_reached / center.voter_count) *
                            100
                          ).toFixed(2)
                        : 0
                    }}%
                  </p>
                </td>

                <!-- Calculate percentage here -->
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Pagination Controls -->
        <!-- <div class="pagination">
          <button
            @click="goToPageDesc(currentPageDesc - 1)"
            :disabled="currentPageDesc === 1"
          >
            Previous
          </button>
          <span>Page {{ currentPageDesc }} of {{ totalPagesDesc }}</span>
          <button
            @click="goToPageDesc(currentPageDesc + 1)"
            :disabled="currentPageDesc === totalPagesDesc"
          >
            Next
          </button>
        </div> -->
        <hr />
      </div>
      <div
        class="col-lg-12"
        style="background-color: white; border-radius: 10px"
      >
        <h6
          class="text-center text-secondary font-weight-bolder mb-3"
          style="margin-top: 10px"
        >
          {{ $t("Least 5 Voting Centers") }}
        </h6>
        <!-- Table Heading -->
        <div class="table-responsive" style="margin-top: -15px">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  AC No
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Part No
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Voting Center
                </th>

                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Voter Count
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Voters Reached
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  %
                </th>
                <!-- New column -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(center, index) in sortedVotingCenters" :key="index">
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ center.ac_no }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ center.partno }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ center.vcenter }}
                  </p>
                </td>

                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ center.voter_count }}
                  </p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">
                    {{ center.voters_reached }}
                  </p>
                </td>
                <td>
                  <p
                    class="text-xs font-weight-bold mb-0"
                    :class="{
                      'percentage-red': center.voter_count === 0,
                      'percentage-green': center.voter_count > 0,
                    }"
                  >
                    {{
                      center.voter_count > 0
                        ? (
                            (center.voters_reached / center.voter_count) *
                            100
                          ).toFixed(2)
                        : 0
                    }}%
                  </p>
                </td>

                <!-- Calculate percentage here -->
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Pagination Controls -->
        <!-- <div class="pagination">
          <button
            @click="goToPageAsc(currentPageAsc - 1)"
            :disabled="currentPageAsc === 1"
          >
            Previous
          </button>
          <span>Page {{ currentPageAsc }} of {{ totalPagesAsc }}</span>
          <button
            @click="goToPageAsc(currentPageAsc + 1)"
            :disabled="currentPageAsc === totalPagesAsc"
          >
            Next
          </button>
        </div> -->
        <hr />
      </div>

      <!-- Second table - Least 5 Voting Centers -->
    </div>

    <div class="mt-4 row" v-if="userType == '1'">
      <div class="mb-4 col-lg-12 mb-lg-0">
        <div class="card z-index-2">
          <div class="p-3 card-body">
            <reports-bar-chart
              id="chart-bar"
              :title="$t('title_opinion_poll_weekly')"
              description="(<strong>+23%</strong>) than last week"
              :chart="{
                labels: [
                  'Apr',
                  'May',
                  'Jun',
                  'Jul',
                  'Aug',
                  'Sep',
                  'Oct',
                  'Nov',
                  'Dec',
                ],
                datasets: {
                  label: 'Sales',
                  data: [450, 200, 100, 220, 500, 100, 400, 230, 500],
                },
              }"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row my-4">
      <div class="col-lg-6 col-md-6 mb-md-0 mb-4">
        <projects-card />
      </div>
      <div class="col-lg-3 col-md-6">
        <timeline-list
          class="h-100"
          :title="$t('title_upcoming_team_task')"
          description=""
        >
          <hr style="margin-top: -20px" />
          <timeline-item
            v-for="task in upcomingTasks"
            :key="task.id"
            color="info"
            icon="check-bold"
            :title="task.task_name"
            :date-time="new Date(task.start_date_time).toLocaleDateString()"
          />
        </timeline-list>
      </div>

      <div class="col-lg-3 col-md-6">
        <timeline-list
          class="h-100"
          :title="$t('title_upcoming_events')"
          description=""
          ><hr style="margin-top: -20px" />
          <timeline-item
            v-for="event in upcomingEvents"
            :key="event.id"
            color="info"
            icon="check-bold"
            :title="event.event_name"
            :date-time="`${new Date(
              event.event_start
            ).toLocaleDateString()} - ${new Date(
              event.event_end
            ).toLocaleDateString()}`"
          />
        </timeline-list>
      </div>
    </div>
  </div>
</template>

<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
// import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import TimelineList from "./components/TimelineList.vue";
import TimelineItem from "./components/TimelineItem.vue";
import ProjectsCard from "./components/ProjectsCard.vue";
import GoogleMapForDashboard from "@/views/components/GoogleMapForDashboard.vue"; // Correct path
import axios from "axios";
import US from "../assets/img/icons/flags/US.png";
import DE from "../assets/img/icons/flags/DE.png";
import GB from "../assets/img/icons/flags/GB.png";
import BR from "../assets/img/icons/flags/BR.png";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";

export default {
  name: "dashboard-default",
  components: {
    MiniStatisticsCard,
    ReportsBarChart,
    // GradientLineChart,
    ProjectsCard,
    TimelineList,
    TimelineItem,
    GoogleMapForDashboard,
  },
  data() {
    return {
      votingCenters: [],
      itemsPerPage: 5, // Number of items per page
      currentPageAsc: 1, // Current page for ascending order table
      currentPageDesc: 1,
      userType: localStorage.getItem("installation_type"),
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      openSurveys: 0,
      surveyQuestions: 0,
      vehicles: 0,
      totalUsers: 0,
      totalEvents: 0,
      upcomingTasks: [],
      upcomingEvents: [],
      mapMarkers: [],
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
    };
  },
  computed: {
    // Ascending order sorting with pagination for the first table
    sortedVotingCenters() {
      const sortedCenters = [...this.votingCenters].sort(
        (a, b) => (a.voters_reached || 0) - (b.voters_reached || 0)
      ); // Sort by 'voters_reached' ascending
      const startIndex = (this.currentPageAsc - 1) * this.itemsPerPage;
      return sortedCenters.slice(startIndex, startIndex + this.itemsPerPage); // Paginate
    },

    // Descending order sorting with pagination for the second table
    sortedVotingCentersDescending() {
      const sortedCenters = [...this.votingCenters].sort(
        (a, b) => (b.voters_reached || 0) - (a.voters_reached || 0)
      ); // Sort by 'partno' descending
      const startIndex = (this.currentPageDesc - 1) * this.itemsPerPage;
      return sortedCenters.slice(startIndex, startIndex + this.itemsPerPage); // Paginate
    },

    // Calculate total pages for pagination of the ascending order table
    totalPagesAsc() {
      return Math.ceil(this.votingCenters.length / this.itemsPerPage);
    },

    // Calculate total pages for pagination of the descending order table
    totalPagesDesc() {
      return Math.ceil(this.votingCenters.length / this.itemsPerPage);
    },
  },

  mounted() {
    this.fetchDashboardData();
    this.fetchMapMarkers();
    this.fetchVotingCenters();
  },
  methods: {
    goToPageAsc(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPagesAsc) {
        this.currentPageAsc = pageNumber;
      }
    },

    goToPageDesc(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPagesDesc) {
        this.currentPageDesc = pageNumber;
      }
    },
    fetchDashboardData() {
      const token = localStorage.getItem("token");
      const tenantId = localStorage.getItem("tenantId");
      const defaultacno = localStorage.getItem("defaultacno");

      axios
        .get(
          `https://rushivarya-prd-loksevak-mix-dot-bhmd-dev-app-navya.el.r.appspot.com/api/v1/admin/${tenantId}/dashboard-data`,
          {
            headers: {
              "Content-type": "application/json",
              Authorization: token,
            },
            params: {
              acno: defaultacno, // Add acno as a query parameter
            },
          }
        )
        .then((response) => {
          const {
            voter_count,
            voter_info_count,
            office_count,
            user_count,
            upcoming_events,
            upcoming_tasks,
          } = response.data;

          console.log("API Response:", response.data);

          this.openSurveys = voter_count ?? 0;
          this.surveyQuestions = voter_info_count ?? 0;
          this.vehicles = office_count ?? 0;
          this.totalUsers = user_count ?? 0;
          this.upcomingEvents = upcoming_events ?? [];
          this.upcomingTasks = upcoming_tasks ?? [];
        })
        .catch((error) => {
          console.error("Error fetching dashboard data:", error);

          this.openSurveys = 0;
          this.surveyQuestions = 0;
          this.vehicles = 0;
          this.totalUsers = 0;
          this.upcomingEvents = [];
          this.upcomingTasks = [];
        });
    },
    /*fetchMapMarkers() {
      const token = localStorage.getItem("token");
      const tenantId = localStorage.getItem("tenantId");
      const userType = localStorage.getItem("installation_type");

      let apiUrl =
        userType == 1
          ? `https://rushivarya-prd-loksevak-mix-dot-bhmd-dev-app-navya.el.r.appspot.com/api/v1/admin/${tenantId}/get-survey-maps-data`
          : `https://rushivarya-prd-loksevak-mix-dot-bhmd-dev-app-navya.el.r.appspot.com/api/v1/admin/${tenantId}/dashboard-new-voter-center-location`;

      axios
        .get(apiUrl, {
          headers: {
            "Content-type": "application/json",
            Authorization: token,
          },
        })
        .then((response) => {
          // Handling for userType 1 (Survey Data)
          if (userType == 1) {
            const results = response.data.results;

            // Clear previous markers
            this.mapMarkers = [];

            this.mapMarkers = results.map((result) => {
              const mapLocation = result.map_location;
              const [lng, lat] = mapLocation.coordinates;

              return {
                lat: lat,
                lng: lng,
                title: `Question ID: ${result.id}`,
                infoWindowContent: `
              <div>
                <p>Question: ${result.question}</p>
                <p>Answer: ${result.answer}</p>
                <p>Category: ${result.survey_category_master.category_name}</p>
                <p>Total Weightage: ${result.total_weightage}</p>
                <p>Date: ${result.date}</p>
              </div>
            `,
              };
            });

            this.initializeMap();
          }
          // Handling for userType 2 (Voting Center Data)
          else if (userType == 2) {
            const results = response.data;

            // Separate data by acno and partno
            const groupedMarkers = {};

            results.forEach((result) => {
              const acno = result.acno;
              const partno = result.partno;

              // Create a key based on acno and partno
              const key = `AC${acno}-Part${partno}`;

              // Initialize the group if it doesn't exist
              if (!groupedMarkers[key]) {
                groupedMarkers[key] = {
                  markers: [],
                  acno: acno,
                  partno: partno,
                };
              }

              const mapLocation = result.voting_center_location.coordinates;
              const [lng, lat] = mapLocation;

              // Add marker details to the group
              groupedMarkers[key].markers.push({
                lat: lat,
                lng: lng,
                vcenter: result.vcenter,
                section: result.section,
                totalVoterInfo: result.total_voter_info,
              });
            });

            // Clear previous markers
            this.mapMarkers = [];

            // Loop through grouped markers to create markers and their corresponding InfoWindows with tables
            Object.values(groupedMarkers).forEach((group) => {
              const firstMarker = group.markers[0]; // Use the first marker's coordinates
              const infoWindowContent = `
            <div>
              <h5>AC No: ${group.acno}, Part No: ${group.partno}</h5>
              <table border="1" cellpadding="5" cellspacing="0" style="width:100%">
                <thead>
                  <tr>
                    <th>Voting Center</th>
                    <th>Area</th>
                    <th style="text-align:center;width:80px">Total Voter </th>
                  </tr>
                </thead>
                <tbody>
                  ${group.markers
                    .map(
                      (marker) => `
                    <tr>
                      <td>${marker.vcenter}</td>
                      <td>${marker.section}</td>
                      <td style="text-align:center">${marker.totalVoterInfo}</td>
                    </tr>
                  `
                    )
                    .join("")}
                </tbody>
              </table>
            </div>
          `;

              // Create one marker for each group (AC/PartNo) with table in InfoWindow
              this.mapMarkers.push({
                lat: firstMarker.lat,
                lng: firstMarker.lng,
                title: `AC No: ${group.acno}, Part No: ${group.partno}`,
                infoWindowContent: infoWindowContent,
              });
            });

            // Initialize or update the map with the new markers
            this.initializeMap();
          }
        })
        .catch((error) => {
          console.error("Error fetching map data:", error);
        });
    },*/

    fetchMapMarkers() {
  const token = localStorage.getItem("token");
  const tenantId = localStorage.getItem("tenantId");
  const defaultacno = localStorage.getItem("defaultacno");

  const apiUrl = `https://rushivarya-prd-loksevak-mix-dot-bhmd-dev-app-navya.el.r.appspot.com/api/v1/admin/${tenantId}/dashboard-new-voter-center-location`;

  axios
    .get(apiUrl, {
      headers: {
        "Content-type": "application/json",
        Authorization: token,
      },
      params: {
        acno: defaultacno, // Add acno as a query parameter
      },
    })
    .then((response) => {
      const results = response.data;

      // Filter out entries where voting_center_location is null
      const validResults = results.filter(
        (result) => result.voting_center_location !== null
      );

      // Separate data by acno and partno
      const groupedMarkers = {};

      validResults.forEach((result) => {
        const acno = result.acno;
        const partno = result.partno;

        // Create a key based on acno and partno
        const key = `AC${acno}-Part${partno}`;

        // Initialize the group if it doesn't exist
        if (!groupedMarkers[key]) {
          groupedMarkers[key] = {
            markers: [],
            acno: acno,
            partno: partno,
          };
        }

        const mapLocation = result.voting_center_location.coordinates;
        const [lng, lat] = mapLocation;

        // Add marker details to the group
        groupedMarkers[key].markers.push({
          lat: lat,
          lng: lng,
          vcenter: result.vcenter,
          section: result.section,
          totalVoterInfo: result.total_voter_info,
        });
      });

      // Clear previous markers
      this.mapMarkers = [];

      // Loop through grouped markers to create markers and their corresponding InfoWindows with tables
      Object.values(groupedMarkers).forEach((group) => {
        const firstMarker = group.markers[0]; // Use the first marker's coordinates
        const infoWindowContent = `
          <div>
            <h5>AC No: ${group.acno}, Part No: ${group.partno}</h5>
            <table border="1" cellpadding="5" cellspacing="0" style="width:100%">
              <thead>
                <tr>
                  <th>Voting Center</th>
                  <th>Area</th>
                  <th style="text-align:center;width:80px">Total Voter</th>
                </tr>
              </thead>
              <tbody>
                ${group.markers
                  .map(
                    (marker) => `
                  <tr>
                    <td>${marker.vcenter}</td>
                    <td>${marker.section}</td>
                    <td style="text-align:center">${marker.totalVoterInfo}</td>
                  </tr>
                `
                  )
                  .join("")}
              </tbody>
            </table>
          </div>
        `;

        // Create one marker for each group (AC/PartNo) with table in InfoWindow
        this.mapMarkers.push({
          lat: firstMarker.lat,
          lng: firstMarker.lng,
          title: `AC No: ${group.acno}, Part No: ${group.partno}`,
          infoWindowContent: infoWindowContent,
        });
      });

      // Initialize or update the map with the new markers
      this.initializeMap();
    })
    .catch((error) => {
      console.error("Error fetching map data:", error);
    });
}
,
    fetchVotingCenters() {
      const token = localStorage.getItem("token");
      const tenantId = localStorage.getItem("tenantId");
      const defaultacno = localStorage.getItem("defaultacno");

      axios
        .get(
          `https://rushivarya-prd-loksevak-mix-dot-bhmd-dev-app-navya.el.r.appspot.com/api/v1/admin/${tenantId}/matdan-kendra-list`,
          {
            headers: {
              "Content-type": "application/json",
              Authorization: token,
            },
            params: {
              acno: defaultacno, // Add acno as a query parameter
            },
          }
        )
        .then((response) => {
          console.log("Fetched Voting Centers:", response.data);
          this.votingCenters = response.data || [];
        })
        .catch((error) => {
          console.error("Error fetching voting center data:", error);
          this.votingCenters = [];
        });
    },
    initializeMap() {
      const mapOptions = {
        zoom: 12,
        center:
          this.newLocation.area.length > 0
            ? this.newLocation.area[0]
            : { lat: 18.5204, lng: 73.8567 }, // Default center if no area
      };

      // Initialize the map
      this.map = new google.maps.Map(
        document.getElementById("map"),
        mapOptions
      );

      // Draw the polygon if userType is 2
      if (this.newLocation.area.length > 0) {
        this.drawPolygon();
      }

      // Place markers for userType 1
      if (this.mapMarkers.length > 0) {
        this.mapMarkers.forEach((markerData) => {
          const marker = new google.maps.Marker({
            position: { lat: markerData.lat, lng: markerData.lng },
            title: markerData.title,
            map: this.map,
          });

          // Info window for each marker
          const infoWindow = new google.maps.InfoWindow({
            content: markerData.infoWindowContent,
          });

          marker.addListener("click", () => {
            infoWindow.open(this.map, marker);
          });
        });
      }
    },

    drawPolygon() {
      if (this.polygon) {
        this.polygon.setMap(null); // Remove existing polygon if present
      }

      if (this.newLocation.area.length > 0) {
        // Create the polygon with the provided coordinates
        this.polygon = new google.maps.Polygon({
          paths: this.newLocation.area,
          strokeColor: "#000000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#000000",
          fillOpacity: 0.35,
          editable: true,
        });

        // Set the polygon on the map
        this.polygon.setMap(this.map);

        // Fit the map to the polygon's bounds
        const bounds = new google.maps.LatLngBounds();
        this.newLocation.area.forEach((point) => bounds.extend(point));
        this.map.fitBounds(bounds);
      }
    },

    addPolygonListeners(polygon) {
      google.maps.event.addListener(polygon.getPath(), "set_at", () => {
        this.updatePolygonCoords();
      });
      google.maps.event.addListener(polygon.getPath(), "insert_at", () => {
        this.updatePolygonCoords();
      });
    },

    updatePolygonCoords() {
      this.newLocation.area = this.polygon
        .getPath()
        .getArray()
        .map((coord) => ({
          lat: coord.lat(),
          lng: coord.lng(),
        }));
    },
  },
};
</script>

<style scoped>
.container-fluid {
  padding: 0 2rem;
}

.card {
  border-radius: 0.5rem;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 5%);
}

.bg-gradient-success {
  background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%) !important;
}
.percentage-red {
  color: red;
}
.percentage-green {
  color: green;
}
</style>
