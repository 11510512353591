// import axios from "axios";
// const token = localStorage.getItem('token');
// console.log(token);
// export default axios.create({
//   baseURL: "http://localhost:8080/api/v1/admin",
//   headers: {
//     "Content-type": "application/json",
//     "Authorization ": token,
//   }
// });

import axios from 'axios';
const apiClient = axios.create({
  baseURL: `https://rushivarya-prd-loksevak-mix-dot-bhmd-dev-app-navya.el.r.appspot.com/api/v1/admin`,
  headers: {
    'Content-type': 'application/json'
  }
});

apiClient.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  const tenantId = localStorage.getItem('tenantId');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  if (tenantId) {
    config.baseURL = `${config.baseURL}/${tenantId}`;
  } 
  return config;
}, error => {
  return Promise.reject(error);
});

export default apiClient;
